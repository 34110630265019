import React, { Component } from "react";
import { ScrollView, View, Dimensions, Pressable } from "react-native";
import { Button, Dialog, IconButton, Portal, RadioButton, Text } from "react-native-paper";
import { connect } from "react-redux";

import { updateAddress } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import * as yup from "yup";
import { desktopBreakpoint, getContrastColor, tabletBreakpoint, getAccessToken } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { customerDataStyles } from "./CustomerDataStyles";
import CustomDropdown from "../../helpers/inputField/CustomDropdown";

const yupObject = yup.object().shape({
    firstName: yup.string().required("Bitte einen Vornamen eingeben"),
    lastName: yup.string().required("Bitte einen Nachnamen eingeben"),
    zipCode: yup
        .string()
        .matches(/^\d{5}$/, "Ungültige Postleitzahl")
        .required("Bitte Postleitzahl eingeben"),
    city: yup.string().required("Bitte eine Stadt eingeben"),
    street: yup.string().required("Bitte den Straßennamen eingeben"),
    houseNumber: yup.string().required("Bitte geben Sie eine Hausnummer ein"),
    countryId: yup.string().required("Bitte wählen Sie ein Land"),
});

class AddAddressPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "",
            street: "",
            houseNumber: "",
            zipCode: "",
            firstName: props.account?.customer?.firstName || "",
            lastName: props.account?.customer?.familyName || "",
            title: props.account?.customer?.title || "Herr",
            company: "",
            addressDetails: "",
            addressIndex: undefined,
            isDefaultDeliveryAddress: false,
            isDefaultBillingAddress: false,
            tempTitle: props.account?.customer?.title || "Herr",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            errors: {},
            addressId: 0,
            availableCountries: [],
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        const { customer } = this.props.account;
        Dimensions.addEventListener("change", this.updateDimensions);

        this.loadAvailableCountries();

        if (this.props.edit) {
            this.setState({
                street: this.props.addressToEdit.street,
                city: this.props.addressToEdit.city,
                company: this.props.addressToEdit.company,
                addressDetails: this.props.addressToEdit.addressDetails,
                houseNumber: this.props.addressToEdit.housenumber,
                zipCode: this.props.addressToEdit.zip,
                title: this.props.addressToEdit.title,
                tempTitle: this.props.addressToEdit.title,
                firstName: this.props.addressToEdit.firstName,
                lastName: this.props.addressToEdit.lastName,
                isDefaultDeliveryAddress: this.props.addressToEdit.isDefaultDeliveryAddress,
                isDefaultBillingAddress: this.props.addressToEdit.isDefaultBillingAddress,
                addressIndex: this.props.addressIndex,
                addressId: this.props.addressToEdit.addressId ? this.props.addressToEdit.addressId : 0,
            });
        } else {
            const defaultCountry = this.state.availableCountries.find((country) => country.isDefault);
            if (defaultCountry) {
                this.setState({
                    countryId: defaultCountry.countryId,
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.edit && prevState.availableCountries.length === 0 && this.state.availableCountries.length > 0) {
            const selectedCountry = this.props.addressToEdit.country
                ? this.state.availableCountries.find((country) => country.name === this.props.addressToEdit.country)
                : null;

            this.setState({
                countryId: selectedCountry ? selectedCountry.countryId : "",
            });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    loadAvailableCountries() {
        const availableCountries = this.props.settings.store.countries;

        this.setState({ availableCountries }, () => {
            if (!this.props.edit) {
                const defaultCountry = availableCountries.find((country) => country.isDefault);
                if (defaultCountry) {
                    this.setState({ countryId: defaultCountry.countryId });
                }
            } else {
                const selectedCountry = this.props.addressToEdit.country
                    ? availableCountries.find((country) => country.name === this.props.addressToEdit.country)
                    : null;

                this.setState({
                    countryId: selectedCountry ? selectedCountry.countryId : "",
                });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { visible, togglePopup, numberOfAddressesSaved, account, update, theme } = this.props;
        const { customer } = this.props.account;

        var hasErrors = {
            formErrors: true,
        };

        let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            zipCode: this.state.zipCode,
            city: this.state.city,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            countryId: this.state.countryId,
        };
        this.setState({ errors: {} });
        yupObject
            .validate(data, { abortEarly: false })
            .then(() => {
                hasErrors.formErrors = false;
                let indexToUpdate = store
                    .dispatch(
                        updateAddress(
                            account.customer.customerUid,
                            this.state.title ? this.state.title : customer.title,
                            this.state.firstName ? this.state.firstName : customer.firstName,
                            this.state.lastName ? this.state.lastName : customer.familyName,
                            // this.props.edit ? this.state.addressIndex : numberOfAddressesSaved,
                            this.state.city,
                            this.state.street,
                            this.state.houseNumber,
                            this.state.zipCode,
                            this.state.company,
                            this.state.addressDetails,
                            this.state.isDefaultBillingAddress,
                            this.state.isDefaultDeliveryAddress,
                            this.state.addressUid,
                            this.state.addressId,
                            this.state.countryId,
                            getAccessToken()
                        )
                    )
                    .then(() => {
                        update();
                        togglePopup();
                    });
            })
            .catch((err) => {
                console.log(err);
                const errors = {};
                err.inner.forEach((errElement) => {
                    errors[errElement.path] = errElement.message;
                });
                this.setState({ errors });
            });
    };

    render() {
        const { visible, togglePopup, numberOfAddressesSaved, account, update, theme } = this.props;
        const { customer } = this.props.account;
        const { windowWidth, errors, availableCountries, countryId } = this.state;

        const sortedCountries = [...availableCountries].sort((a, b) => a.name.localeCompare(b.name));

        return (
            <Portal>
                <Dialog
                    visible={visible}
                    onDismiss={() => {
                        togglePopup();
                    }}
                    style={[
                        styles.addressDialogContainer,
                        {
                            backgroundColor: theme.colors.surface,
                            left: windowWidth <= desktopBreakpoint ? "calc(50% - 45vw)" : "calc(50% - 25vw)",
                            width: windowWidth <= desktopBreakpoint ? "90vw" : "50vw",
                        },
                    ]}
                    dismissable={false}
                >
                    <IconButton
                        icon="close"
                        size={24}
                        onPress={togglePopup}
                        style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                        iconColor={getContrastColor(theme.colors.primary)}
                        rippleColor="transparent"
                    />
                    <Dialog.Title style={styles.dialogHeadline}>
                        {this.props.edit ? "Adresse bearbeiten" : "Adresse hinzufügen"}
                    </Dialog.Title>
                    <Dialog.ScrollArea
                        style={{
                            borderColor: theme.colors.surface,
                        }}
                    >
                        <ScrollView>
                            <Dialog.Content>
                                <View style={customerDataStyles.fieldsWrapper}>
                                    <View style={customerDataStyles.fieldRow}>
                                        <Text>Anrede</Text>

                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <Pressable
                                                onPress={(event) => {
                                                    this.setState({ title: "Herr" });
                                                    this.setState({ tempTitle: "Herr" });
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginRight: 15,
                                                    }}
                                                >
                                                    <RadioButton.IOS
                                                        value="Herr"
                                                        status={
                                                            this.state.tempTitle === "Herr" ? "checked" : "unchecked"
                                                        }
                                                        onPress={(event) => {
                                                            this.setState({ title: "Herr" });
                                                            this.setState({ tempTitle: "Herr" });
                                                        }}
                                                    />
                                                    <Text>Herr</Text>
                                                </View>
                                            </Pressable>
                                            <Pressable
                                                onPress={(event) => {
                                                    this.setState({ title: "Frau" });
                                                    this.setState({ tempTitle: "Frau" });
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginRight: 15,
                                                    }}
                                                >
                                                    <RadioButton.IOS
                                                        value="Frau"
                                                        status={
                                                            this.state.tempTitle === "Frau" ? "checked" : "unchecked"
                                                        }
                                                        onPress={(event) => {
                                                            this.setState({ title: "Frau" });
                                                            this.setState({ tempTitle: "Frau" });
                                                        }}
                                                    />
                                                    <Text>Frau</Text>
                                                </View>
                                            </Pressable>
                                            <Pressable
                                                onPress={(event) => {
                                                    this.setState({ title: "Divers" });
                                                    this.setState({ tempTitle: "Divers" });
                                                }}
                                            >
                                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <RadioButton.IOS
                                                        value="Divers"
                                                        status={
                                                            this.state.tempTitle === "Divers" ? "checked" : "unchecked"
                                                        }
                                                        onPress={(event) => {
                                                            this.setState({ title: "Divers" });
                                                            this.setState({ tempTitle: "Divers" });
                                                        }}
                                                    />
                                                    <Text>Divers</Text>
                                                </View>
                                            </Pressable>
                                        </View>
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Vorname"
                                            error={errors.firstName ? true : false}
                                            helperText={errors.firstName ? errors.firstName : ""}
                                            onChange={(value) => this.setState({ firstName: value })}
                                            value={this.state.firstName}
                                            maxLength={20}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Nachname"
                                            error={errors.lastName ? true : false}
                                            helperText={errors.lastName ? errors.lastName : ""}
                                            onChange={(value) => this.setState({ lastName: value })}
                                            value={this.state.lastName}
                                            maxLength={40}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Straße"
                                            error={errors.street ? true : false}
                                            helperText={errors.street ? errors.street : ""}
                                            onChange={(value) => this.setState({ street: value })}
                                            value={this.state.street}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Hausnummer"
                                            error={errors.houseNumber ? true : false}
                                            helperText={errors.houseNumber ? errors.houseNumber : ""}
                                            onChange={(value) => this.setState({ houseNumber: value })}
                                            value={this.state.houseNumber}
                                            maxLength={50}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Postleitzahl"
                                            error={errors.zipCode ? true : false}
                                            helperText={errors.zipCode ? errors.zipCode : ""}
                                            onChange={(value) => this.setState({ zipCode: value })}
                                            value={this.state.zipCode}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Stadt"
                                            error={errors.city ? true : false}
                                            helperText={errors.city ? errors.city : ""}
                                            onChange={(value) => this.setState({ city: value })}
                                            value={this.state.city}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Firma"
                                            onChange={(value) => this.setState({ company: value })}
                                            value={this.state.company}
                                        />
                                    </View>

                                    <View
                                        style={
                                            (customerDataStyles.fieldRow,
                                            windowWidth <= desktopBreakpoint
                                                ? customerDataStyles.fieldFormFullRow
                                                : customerDataStyles.fieldFormSplitRow)
                                        }
                                    >
                                        <CustomInputField
                                            label="Adresszusatz"
                                            onChange={(value) => this.setState({ addressDetails: value })}
                                            value={this.state.addressDetails}
                                        />
                                    </View>

                                    <View style={{ width: windowWidth <= desktopBreakpoint ? "100%" : "98%" }}>
                                        <CustomDropdown
                                            label="Land"
                                            error={!!errors.countryId}
                                            helperText={errors.countryId || ""}
                                            onChange={(value) => this.setState({ countryId: Number(value) })}
                                            items={sortedCountries.map((country) => ({
                                                label: country.name,
                                                value: country.countryId,
                                            }))}
                                            value={countryId}
                                            placeholder="Wählen Sie ein Land"
                                        />
                                    </View>
                                </View>
                            </Dialog.Content>
                        </ScrollView>
                    </Dialog.ScrollArea>
                    <Dialog.Actions
                        style={{ justifyContent: "center", width: "90%", marginLeft: "auto", marginRight: "auto" }}
                    >
                        <View
                            style={
                                windowWidth <= tabletBreakpoint
                                    ? customerDataStyles.containerFullRow
                                    : customerDataStyles.containerSplitRow
                            }
                        >
                            <Button
                                onPress={togglePopup}
                                style={{
                                    backgroundColor: theme.colors.error,
                                    flex: 1,
                                    marginRight: windowWidth <= tabletBreakpoint ? 0 : "2%",
                                    marginBottom: windowWidth <= tabletBreakpoint ? 10 : 10,
                                }}
                                textColor={getContrastColor(theme.colors.primary)}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                textColor={getContrastColor(theme.colors.primary)}
                                style={{
                                    backgroundColor: theme.colors.primary,
                                    flex: 1,
                                    marginRight: windowWidth <= tabletBreakpoint ? 0 : "2%",
                                    marginBottom: windowWidth <= tabletBreakpoint ? 10 : 10,
                                }}
                                uppercase={false}
                                onPress={this.handleSubmit}
                            >
                                {this.props.edit ? "Änderungen speichern" : "Neue Adresse speichern"}
                            </Button>
                        </View>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}

export default connect(mapStateToProps)(AddAddressPopup);
