import React from "react";
import { HelperText, TextInput } from "react-native-paper";
import { Platform } from "react-native";

export default function CustomInputField({
    disabled = false,
    error = false,
    helperText = "",
    id = "",
    index = 0,
    label = "",
    maxLength = 255,
    minRows = 1,
    multiline = false,
    onBlur = () => {},
    onChange = () => {},
    placeholder = "",
    style = {},
    textColor = "#000000",
    labelColor = "#000000",
    type = "text",
    value = "",
    mode = "outlined",
}) {
    const isPassword = type === "password";
    const secureTextEntry = isPassword;
    const keyboardType = type === "numeric" ? "numeric" : type === "email" ? "email-address" : "default";
    const textInputHeight = multiline ? minRows * 20 : undefined;
    const autoComplete = Platform.OS === "web" ? getDefaultAutoComplete(id, label) : undefined;

    const customTheme = {
        colors: {
            onSurfaceVariant: labelColor,
            onSurface: labelColor,
            placeholder: labelColor,
            primary: labelColor,
        },
    };

    return (
        <>
            <TextInput
                activeOutlineColor={labelColor}
                autoComplete={autoComplete}
                disabled={disabled}
                error={error}
                id={id}
                key={index}
                keyboardType={keyboardType}
                label={label}
                maxLength={maxLength}
                mode={mode}
                multiline={multiline}
                onBlur={onBlur}
                onChangeText={onChange}
                outlineColor={labelColor}
                placeholder={placeholder}
                secureTextEntry={secureTextEntry}
                style={[{ ...style }, multiline && { minHeight: textInputHeight }]}
                testID={id}
                textColor={textColor}
                theme={customTheme}
                value={value}
            />
            <HelperText type={error ? "error" : "info"} visible={!!helperText}>
                {helperText}
            </HelperText>
        </>
    );
}

function getDefaultAutoComplete(id, label) {
    if (id.toLowerCase().includes("billing")) {
        switch (label?.toLowerCase()) {
            case "vorname":
                return "billing given-name";
            case "nachname":
                return "billing family-name";
            case "e-mail-adresse":
                return "billing email";
            case "telefonnummer":
                return "billing tel";
            case "straße":
                return "billing address-line1";
            case "hausnummer":
                return "billing address-line2";
            case "plz":
                return "billing postal-code";
            case "stadt":
                return "billing address-level2";
            default:
                return undefined;
        }
    } else {
        switch (label?.toLowerCase()) {
            case "vorname":
                return "given-name";
            case "nachname":
                return "family-name";
            case "e-mail-adresse":
                return "email";
            case "telefonnummer":
                return "tel";
            case "straße":
                return "address-line1";
            case "hausnummer":
                return "address-line2";
            case "plz":
                return "postal-code";
            case "stadt":
                return "address-level2";
            default:
                return undefined;
        }
    }
}
