import moment from "moment";
import React from "react";
import { View } from "react-native";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function OrderInformations(props) {
    const getPaymentTypeString = () => {
        if (!props.data.PaymentTypes) return "Offen";
        return props.data.PaymentTypes.name;
    };

    const getOrderStateString = () => {
        switch (props.data.orderState) {
            case 0:
                return "Muss bestätigt werden";
            case 2:
                return "In Zubereitung";
            case 3:
                return "Zubereitet";
            case 4:
                return "In Auslieferung";
            case 5:
                if (props.data != null && props.data.stornoTransactionId != null) return "Storniert";
                return "Abgeschlossen";
            case 6:
                return "Fehlerhaft";
            case 7:
                return "Storniert";
            case 8:
                return "Genullt";
            case 9:
                return "Stornobuchung";
            default:
                return "Offen";
        }
    };

    return (
        <View>
            <View style={orderTrackingStyles.row}>
                <View style={orderTrackingStyles.rowLabel}>
                    <Text style={orderTrackingStyles.text}>{"Status: " + getOrderStateString()} </Text>
                </View>
            </View>
            <View style={orderTrackingStyles.row}>
                <View style={orderTrackingStyles.rowLabel}>
                    <Text style={orderTrackingStyles.text}>{"Zahlungsweise: " + getPaymentTypeString()}</Text>
                </View>
            </View>
        </View>
    );
}
